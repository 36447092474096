import React, { lazy, Suspense } from 'react';
import { render, RenderResult } from '@testing-library/react';
import { Navigate, useRoutes, MemoryRouter } from 'react-router-dom';

import { DefaultLayout } from 'src/layouts';
import { PATH_AFTER_LOGIN } from 'src/config-global';

import { SplashScreen } from 'src/components/loading-screen';

import { authRoutes } from './auth';
import { errorRoutes } from './error';
import { paths } from '../paths';

// PAGE_IMPORTS
const ScanNf = lazy(() => import("../../modules/demonstration/scan-nf/scan-nf"));
const Home = lazy(() => import("../../modules/demonstration/home/home"));

export const renderWithRouter = (ui: React.ReactElement): RenderResult => render(<MemoryRouter>{ui}</MemoryRouter>);

export default function Router() {
  return useRoutes([
    {
      path: '/',
      element: (
        <Suspense fallback={<SplashScreen/>}>
          <DefaultLayout>
            <Navigate to={PATH_AFTER_LOGIN} replace />
          </DefaultLayout>
        </Suspense>
      ),
    },
    ...authRoutes,
    
    // RELATION_PATH_PAGES
    { path: paths.scanNf.root, element:
      <DefaultLayout>
        <ScanNf />
      </DefaultLayout>
    },
    { path: paths.home.root, element:
      <DefaultLayout>
        <Home />
      </DefaultLayout>
    },

    { path: '*', element: <Navigate to="/404" replace /> },

    ...errorRoutes,

  ]);
}
