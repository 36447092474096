import { useState, useEffect } from 'react';

import Box, { BoxProps } from '@mui/material/Box';
import { LinearProgress, linearProgressClasses, Stack } from '@mui/material';

import Logo from '../logo';

// ----------------------------------------------------------------------

export default function SplashScreen({ sx, ...other }: BoxProps) {
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  if (!mounted) {
    return null;
  }

  return (
    <Box
      sx={{
        right: 0,
        width: 1,
        bottom: 0,
        height: 1,
        zIndex: 9998,
        display: 'flex',
        position: 'absolute',
        alignItems: 'center',
        justifyContent: 'center',
        bgcolor: 'background.default',
        ...sx,
      }}
      {...other}
    >
      <>
        <Stack spacing={2}>
          <Logo customLogo='/logo/logo_full_black_horizontal.png' disabledLink width={245.725} height={49.15}/>
          <LinearProgress 
            sx={{ 
              width: 1, 
              maxWidth: 360, 
              background: 'black',
              '.MuiLinearProgress-bar': {
                background: (theme) => `linear-gradient(135deg, ${theme.palette.secondary.main} 0%, ${theme.palette.primary.main} 100%)`, 
              }
            }} 
          />
        </Stack>
      </>
    </Box>
  );
}
