const ROOTS = {
  AUTH: '/auth',
};

export const paths = {
  root: '/',
  auth: {
    root: ROOTS.AUTH,
    login: `${ROOTS.AUTH}/login`,
  },

  // PAGES
  scanNf:{
    root: '/demonstration/scanNf',
  },
      
  home:{
    root: '/demonstration/home',
  },
      
  example:{
    root: '/example',
  },
      

  testAuthAndGuard:{
    root: '/testAuthAndGuard',
  },

  testRepository:{
    root: '/testRepository',
  },

  error: {
    notFound: '/404',
    forbidden: '/403',
    serverError: '/500',
  },
};
